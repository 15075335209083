.tw-button {
  &__primary {
    min-width: 92px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-transform: none;
    background-color: #3959df;
    color: #fff;
    border: 1px solid transparent; // Allows matching primary and secondary button visual size, even when disabled
    box-shadow: none;

    &:hover {
      color: #9090a6;
      background-color: #3959df;
      box-shadow: none;
    }
  }
  &__secondary {
    min-width: 92px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: none;
    color: #000000;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    mix-blend-mode: normal;
    box-shadow: none;

    &:hover {
      color: #9090a6;
      background-color: #fff;
      box-shadow: none;
    }
  }
  &__positive {
    min-width: 92px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    background-color: #10b981;
    border: none;
    mix-blend-mode: normal;
    box-shadow: none;

    &:hover {
      color: #fff;
      background-color: #009b67;
      box-shadow: none;
    }
  }
  &__negative {
    min-width: 92px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    background-color: #ef4444;
    mix-blend-mode: normal;
    box-shadow: none;
    border: 1px solid transparent; // Allows matching negative and secondary button visual size, even when disabled

    &:hover {
      color: #fff;
      background-color: #d33434;
      box-shadow: none;
    }
  }
  &__clearall {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6b7280;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-transform: none;
    height: 27px;
    left: -4px;
    padding: 0;
    margin: 0;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
  &__text {
    min-width: auto;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    color: #3a4de9;
    background-color: transparent;
    border: none;
    mix-blend-mode: normal;
    box-shadow: none;

    &:hover {
      background-color: transparent;
    }
  }
}
