@import '../../styles/variables.scss';

.snackbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 350px;

  &__loading {
    color: white;
  }

  &__action {
    color: white;
    font-size: 14px;
    font-weight: 500, Medium;
    line-height: 21px;
    text-transform: uppercase;
  }

  &__text {
    font-size: 14px;
    font-weight: 400, Regular;
    line-height: 20px;
  }

  &__icon {
    font-size: 16px;
    margin-right: 10px;
  }

  &__success {
    color: $green-3;
  }

  &__warning {
    color: $orange-1;
  }

  &__error {
    color: $red-1;
  }
}

.snackbar span {
  margin-right: 10px;
}
