$white: #fff;
$white-2: #fafafb;

$nav: #1d212c;
$nav-2: #303b5a;

$black: #000;
$black-1: #171e28;
$black-2: #171725;
$black-3: #0d1f34;
$black-4: #44444f;
$black-5: #7f7f7f;
$black-6: #111827;
$black-7: #1f2937;

$blue-1: #3959df;
$blue-2: #1c243d;
$blue-3: #3a4de9;
$blue-4: #969ff1;
$blue-5: #adb3f3;
$blue-6: #7a88f7;

$light-blue-1: #ebedfd;

$gray-1: #e2e2ea;
$gray-2: #9090a6;
$gray-3: #44444f;
$gray-4: #f6f7fc;
$gray-5: #b5b5be;
$gray-6: #808080;
$gray-7: #7e858e;
$gray-8: #ecedf3;
$gray-9: #7b7b7e75;
$gray-a1: #d8dadd;
$gray-a2: #e5e5e5;
$gray-a3: #979cac;
$gray-a4: #bbbfc9;
$gray-a5: #e4e9ed;
$gray-a6: #f6f7f8;
$gray-a7: #83899c;
$gray-a8: #f2f1f9;
$gray-a9: #f9f7fa;
$gray-b1: #6b7280;
$gray-b2: #f9fafb;
$gray-b3: #9ca3af;
$gray-b4: #e5e7eb;
$gray-b5: #f3f4f6;
$gray-b6: #cbcbcb;

$green-1: #4bbf7b;
$green-2: #4abf7a;
$green-3: #39b76c;

$red-1: #ea5356;

$orange-1: #f5a623;
